.user-roles-chips > div:not(:last-child) {
  margin-right: 13px;
}

.handbooks-add-edit-user {
  &__success-alert {
    min-height: 50px;
    margin: 15px 0 -15px 0;
  }
}
