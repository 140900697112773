@import "./utils/reset.scss";
@import "./utils/vars.scss";
@import "./utils/fonts.scss";
@import "./utils/override.scss";

html {
  line-height: $mainLineHeight;
  font-family: $mainFont;
  font-size: $mainFontSize;
  font-weight: $mainFontWeight;
  color: $mainFontColor;
  height: 100%;

  @media screen and (max-width: $desktopWidth) {
    font-size: 16px;
  }

  @media screen and (max-width: $tableWidth) {
    font-size: 15px;
  }

  @media screen and (max-width: $phoneWidth) {
    font-size: 14px;
  }
}

body {
  overflow-y: scroll;
}

.section {
  position: relative;
  padding: 50px 0;

  @media screen and (max-width: 1280px) {
    padding: 36px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 28px 0;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;

  .wrapper__content {
    display: flex;
    flex-direction: column;

    .container {
      width: 100%;
      flex: 0 0 auto;
      position: relative;
      max-width: 1180px;
      margin: 0 auto;

      @media screen and (max-width: 980px) {
        max-width: 768px;
      }

      @media screen and (max-width: 768px) {
        max-width: 580px;
      }

      @media screen and (max-width: 480px) {
        max-width: 320px;
      }
    }
  }
}

@import "./common.scss";
