.label {
	font-size: 18px;
	font-weight: bold;

	&.margin-bottom-32 {
		margin-bottom: 32px;
	}

	&.margin-bottom-20 {
		margin-bottom: 20px;
	}

	&.margin-top-20 {
		margin-top: 20px;
	}
}
