@import "assets/scss/utils/vars.scss";

.DayPicker-Day {
	border: 1px solid $border-grey;
	border-radius: 0%;

	&:not(.DayPicker-Day--disabled) {
		color: $daypicker-active-day;
	}

	&--disabled {
		color: $daypicker-disabled-day;
		background-color: $daypicker-disabled-background;
	}

	&--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		background-color: unset;
    box-shadow:inset 0px 0px 0px 3px $border-hover;
    color: $daypicker-active-day;
    
    &:hover {
      background-color: $light-blue-color;
    }
	}
}

.DayPicker-WeekdaysRow {
	text-transform: capitalize;
	border-bottom: 4px solid $border-grey;

	& > .DayPicker-Weekday {
		&:nth-child(6),
		&:nth-child(7) {
			color: $error-color;
		}
	}
}
