.modal-excluded {
  width: 775px;
  max-height: 720px;
  overflow-y: auto;

  .expand {
    padding: 0 10px;

    &__content.opened {
      margin-bottom: 31px;
    }
    &__item {
      list-style: none;
      max-width: 50%;

      .float-label {
        margin-top: 10px;
      }
    }
    &__collapser.noBorder {
      border-bottom: none;
    }
  }
  .formButtons {
    padding: 50px;
  }

  .result-import {
    display: flex;
    flex-direction: column;
    padding: 0 50px;

    &__success {
      margin-bottom: 14px;
      font-size: 18px;
    }

    &__error {
      margin-bottom: 40px;
      font-size: 16px;

      & > span {
        display: block;
        overflow-wrap: anywhere;
        margin-top: 16px;
        font-size: 16px;
      }
    }
  }

  .result-list {
    padding: 0 50px 50px;

    .result-length {
      color: #333333;
      font-size: 18px;
      padding-bottom: 30px;
      border-bottom: 4px solid #c8c7cc;
      display: flex;
    }

    &__item {
      border-bottom: 1px solid #cdd1d4;
      display: flex;
      &:nth-child(odd) {
        background: #f9f9f9;
      }

      label::before {
        top: 25%;
      }

      label::after {
        top: 30%;
      }

      .name,
      .address {
        display: flex;
      }

      .name {
        padding-top: 19px;
      }

      .address {
        margin-bottom: 17px;
        font-size: 12px;
      }
    }

    .formButtons {
      padding: 50px 0;
    }
  }
}
