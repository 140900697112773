.tooltip {
  position: absolute;
  left: 50px;
  top: -10px;
  text-align: center;
  text-align: start;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 15px;
  line-height: 1.35;
  display: none;
  background-color: black;
  color: white;
  border-radius: 4px;
  padding: 10px;
  max-width: 290px;
}
