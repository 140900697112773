@import "assets/scss/utils/vars.scss";

.form {
  display: flex;
  flex-direction: column;
  max-width: 623px;
  margin-top: 49px;

  .warning-text {
    color: $error-color;
    margin-top: 0.275rem;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 46px;

    &.margin-bottom-12 {
      margin-bottom: 12px;
    }
  }

  .form__field {
    margin-bottom: 30px;

    &.warning {
      margin-bottom: 0;
      outline: 2px solid $error-color;

      &:hover {
        outline: 2px solid $error-color;
      }

      &:focus {
        outline: 3px solid $error-color;
      }
    }
  }

  .form__buttons {
    margin-top: 15px;

    &.margin-top-64 {
      margin-top: 64px;
    }

    .form__button {
      padding: 17px 36px;

      &:first-child {
        margin-right: 21px;
      }
    }
  }
}
